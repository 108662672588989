<template>
  <div>
    <overlay-loader :loading="fullLoading"></overlay-loader>
    <v-card>
      <v-toolbar class="mt-12 mb-12" color="primary" light>
        <v-toolbar-title class="white--text mx-12">
          <v-card-title class="mt-5 title_doc">List of documentation profile</v-card-title>
        </v-toolbar-title>
        <slot>
          <v-btn class="mr-5" fab color="white" bottom right absolute @click="showNew()">
            <v-icon>mdi-file-document-plus</v-icon>
          </v-btn>
        </slot>
        <slot>
          <v-btn style="margin-left: 45%;" fab bottom absolute @click="toggleIsOpen()">
            <v-icon>{{ !isOpen ? 'mdi-arrow-down-circle-outline' : 'mdi-arrow-up-circle-outline' }}</v-icon>
          </v-btn>
        </slot>
      </v-toolbar>
      <div v-if="isOpen">
        <v-col cols="12">
          <div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details></v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="documents" :search="search" dense :loading="loading"
          loading-text="Loading documents...">
            <template v-slot:item.is_active="{ item }">
              <v-checkbox v-model="item.is_active" disabled hide-details class="mt-0"></v-checkbox>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn x-small class="ma-1" color="accent" outlined rounded @click="showUpdate(item)">
                Update <v-icon small dark right>mdi-pencil</v-icon>
              </v-btn>
              <v-btn x-small class="ma-2" color="error" outlined rounded @click="showDelete(item)">
                Delete <v-icon small dark right>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </div>
    </v-card>
    <v-dialog v-model="newDialog" max-width="900" :retain-focus="false">
      <v-card>
        <v-card-title><span class="text-h5">Create new documentation profile</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field underlined v-model="newDocument.name" label="Name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="newDocument.role"
                  :items="roleList"
                  label="Role*"
                  underlined
                  persistent-hint
                  hint="Who can see this document?"
                  required>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="newDocument.documentation_type"
                  :items="documentationTypeList"
                  label="Documentation type*"
                  underlined
                  required>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="newDocument.minutes_to_read"
                  underlined
                  label="Minutes to read*"
                  type="number"
                  min="0"
                  persistent-hint
                  hint="Minimum minutes for the user to read the document">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox v-model="newDocument.is_active" label="Is Active?" required></v-checkbox>
              </v-col>
              <v-col cols="12 pb-0">
                <v-file-input
                  v-model="file"
                  id="uploadFileCreate"
                  ref="file"
                  name="fileToUpload"
                  label="Upload file*"
                  prepend-icon="mdi-file-document"
                  enctype="multipart/form-data"
                  class="_upload-input"
                  @click:clear="onFileClear"
                  @change="checkFile"
                  underlined
                  :accept="Constants.ACCEPTED_FILE_EXTENSIONS_DOCUMENT_PROFILE.join(', ')"
                ></v-file-input>
              </v-col>
              <v-col cols="12" class="mt-0">
                <AlertMessage
                  :colorTextClass="!uploadButtonFail ? '' : 'red--text'"
                  :colorIcon="!uploadButtonFail ? 'blue' : 'red'"
                  :icon="'info'"
                  :message="acceptedFileExtensionInfoMessage"
                  ></AlertMessage>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="newDialog=false" color="error">Close</v-btn>
          <v-btn outlined rounded :disabled="formValid" @click="createNewDocument()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateDialog" max-width="900" :retain-focus="false">
      <v-card>
        <v-card-title><span class="text-h5">Update documentation profile</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field underlined v-model="selectedDocument.name" label="Name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedDocument.role"
                  :items="roleList"
                  label="Role*"
                  underlined
                  persistent-hint
                  hint="Who can see this document?"
                  required>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedDocument.documentation_type"
                  :items="documentationTypeList"
                  label="Documentation type*"
                  underlined
                  required>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="selectedDocument.minutes_to_read"
                  underlined
                  label="Minutes to read*"
                  type="number"
                  min="0"
                  persistent-hint
                  hint="Minimum minutes for the user to read the document">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox v-model="selectedDocument.is_active" label="Is Active?" required></v-checkbox>
              </v-col>
              <v-col cols="12 pb-0">
                <v-file-input
                  v-model="file"
                  id="uploadFileUpdate"
                  ref="file"
                  name="fileToUpload"
                  label="Update file"
                  prepend-icon="mdi-file-document-refresh"
                  enctype="multipart/form-data"
                  class="_upload-input"
                  @click:clear="onFileClear"
                  hide-details
                  @change="checkFile"
                  underlined
                  :accept="Constants.ACCEPTED_FILE_EXTENSIONS_DOCUMENT_PROFILE.join(', ')"
                ></v-file-input>
                <AlertMessage
                  :colorTextClass="'red--text mb-0'"
                  :colorIcon="'red'"
                  :icon="'warning'"
                  :message="'IMPORTANT! Select your file to update the document only if needed to be replaced, '
                  +'else leave empty'"
                  ></AlertMessage>
              </v-col>
              <v-col cols="12" class="mt-0">
                <AlertMessage
                  :colorTextClass="!uploadButtonFail ? '' : 'red--text'"
                  :colorIcon="!uploadButtonFail ? 'blue' : 'red'"
                  :icon="'info'"
                  :message="acceptedFileExtensionInfoMessage"
                  ></AlertMessage>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined rounded @click="updateDialog=false" color="error">Close</v-btn>
          <v-btn outlined rounded :disabled="formValid" @click="updateDocument()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="900" :retain-focus="false">
      <v-card>
        <v-card-title class="text-h6 justify-center">Are you sure to delete this document?</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <ul>
                  <li><strong>Name</strong>: {{ selectedDocument.name }}</li>
                  <li><strong>Documentation type</strong>: {{ selectedDocument.documentation_type }}</li>
                  <li><strong>Role</strong>: {{ selectedDocument.role }}</li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded @click="deleteDialog=false" color="error">No</v-btn>
        <v-btn outlined rounded @click="deleteDocument()" color="primary">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { accountServices } from '@/services/accountServices';
import { roleManager } from '@/store/roleManager';
import AlertMessage from '@/components/generic/AlertMessage.vue';
import { Constants } from '@/store/constants';

export default {
  name: 'ControlPanelAppDocument',
  components: {
    AlertMessage,
    OverlayLoader,
  },
  data() {
    return {
      AlertMessage,
      Constants,
      roleManager,
      fullLoading: false,
      loading: false,
      isOpen: false,
      newDialog: false,
      deleteDialog: false,
      updateDialog: false,
      search: '',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'documentation_type' },
        { text: 'Role', value: 'role' },
        { text: 'Minutes to read', value: 'minutes_to_read' },
        { text: 'Last updated datestamp', value: 'last_updated_datestamp' },
        { text: 'Is active', value: 'is_active' },
        { text: 'Actions', value: 'actions', align: 'right' },
      ],
      documents: [],
      roleList: roleManager.roleList().concat(['all']),
      documentationTypeList: ['document', 'video', 'image'],
      newDocument: {
        name: '',
        documentation_type: '',
        role: '',
        minutes_to_read: 1,
        is_active: true,
      },
      selectedDocument: {},
      file: null,
      uploadButtonFail: false,
      uploadedSuccessFully: false,
    };
  },
  async mounted() {
    await Promise.all([
      this.getDocumentationProfile(),
    ]);
  },
  computed: {
    formValid() {
      //
      // const check = this.updateDialog ? this.selectedDocument : this.newDocument;
      // return !(
      //   [null, '', undefined].includes(check.name)
      //   && [null, '', undefined].includes(check.minutes_to_read)
      //   && [null, '', undefined].includes(check.role)
      //   && [null, '', undefined].includes(check.documentation_type)
      // );
      return false;
    },
    acceptedFileExtensionInfoMessage() {
      return `Only files with extension:
      ${Constants.ACCEPTED_FILE_EXTENSIONS_DOCUMENT_PROFILE.slice(0, -1).join(', ')}
      or ${Constants.ACCEPTED_FILE_EXTENSIONS_DOCUMENT_PROFILE.slice(-1)}`;
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
    onFileClear() {
      this.uploadButtonFail = false;
    },
    resetNewDocument() {
      this.newDocument = {
        name: '',
        documentation_type: '',
        role: '',
        minutes_to_read: 1,
        is_active: true,
      };
    },
    async checkFile(file) {
      if (file) {
        const ext = file.name.split('.').pop();
        if (!Constants.ACCEPTED_FILE_EXTENSIONS_DOCUMENT_PROFILE.includes(`.${ext}`)) {
          this.uploadButtonFail = true;
        }
      }
    },
    getDocumentationProfile() {
      this.loading = true;
      accountServices.getDocumentationProfile()
        .then((response) => {
          this.documents = response.data;
          this.loading = false;
        }).catch(() => {});
    },
    showNew() {
      this.resetNewDocument();
      this.newDialog = true;
      this.file = null;
    },
    showUpdate(item) {
      this.file = null;
      this.selectedDocument = { ...item };
      this.updateDialog = true;
    },
    showDelete(item) {
      this.selectedDocument = { ...item };
      this.deleteDialog = true;
    },
    createNewDocument() {
      this.fullLoading = true;
      const archive = document.querySelector('#uploadFileCreate');
      const formData = new FormData();
      if (!archive.files[0]) return;
      const ext = archive.files[0].name.split('.').pop();
      if (!Constants.ACCEPTED_FILE_EXTENSIONS_DOCUMENT_PROFILE.includes(`.${ext}`)) {
        this.uploadButtonFail = true;
        return;
      }
      this.uploadButtonFail = false;
      formData.append('name', this.newDocument.name);
      formData.append('documentation_type', this.newDocument.documentation_type);
      formData.append('role', this.newDocument.role);
      formData.append('minutes_to_read', this.newDocument.minutes_to_read);
      formData.append('is_active', this.newDocument.is_active);
      formData.append('document', archive.files[0]);
      formData.append('title', archive.files[0].name);
      accountServices.createDocumentationProfile(formData)
        .then((response) => {
          this.uploadedSuccessFully = true;
          this.fullLoading = false;
          this.newDialog = false;
          this.documents.push(response.data);
          this.$emit('showErrorAlert', 'success', `Document ${this.selectedDocument.name} updated`);
        })
        .catch(() => {
          this.fullLoading = false;
          this.$emit('showErrorAlert', 'error', 'Document can not be updated');
        });
    },
    deleteDocument() {
      this.fullLoading = true;
      accountServices.deleteDocumentationProfile(this.selectedDocument.id)
        .then(() => {
          this.deleteDialog = false;
          this.$emit('showErrorAlert', 'success', `Document ${this.selectedDocument.name} deleted`);
          this.documents = this.documents.filter((item) => item.id !== this.selectedDocument.id);
          this.selectedDocument = {};
          this.fullLoading = false;
        })
        .catch(() => {
          this.$emit('showErrorAlert', 'error', 'Document can not be deleted');
        });
    },
    updateDocument() {
      this.fullLoading = true;
      const archive = document.querySelector('#uploadFileUpdate');
      const formData = new FormData();
      if (archive.files[0]) {
        const ext = archive.files[0].name.split('.').pop();
        if (!Constants.ACCEPTED_FILE_EXTENSIONS_DOCUMENT_PROFILE.includes(`.${ext}`)) {
          this.uploadButtonFail = true;
          return;
        }
      }
      this.uploadButtonFail = false;
      formData.append('name', this.selectedDocument.name);
      formData.append('documentation_type', this.selectedDocument.documentation_type);
      formData.append('role', this.selectedDocument.role);
      formData.append('minutes_to_read', this.selectedDocument.minutes_to_read);
      formData.append('is_active', this.selectedDocument.is_active);
      if (archive.files[0]) {
        formData.append('document', archive.files[0]);
        formData.append('title', archive.files[0].name);
      }
      accountServices.updateDocumentationProfile(this.selectedDocument.id, formData)
        .then((response) => {
          this.uploadedSuccessFully = true;
          this.fullLoading = false;
          this.updateDialog = false;
          this.$emit('showErrorAlert', 'success', `Document ${this.selectedDocument.name} updated`);
          this.documents = this.documents.filter((item) => item.id !== this.selectedDocument.id);
          this.documents.push(response.data);
        })
        .catch(() => {
          this.fullLoading = false;
          this.$emit('showErrorAlert', 'error', 'Document can not be updated');
        });
    },
  },
};
</script>
<style>
</style>
